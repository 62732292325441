import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export const ThridSectionCard = ({ data, index }) => {
  const { groupProductName, groupProductDescription, groupProductImg } = data
  const imgFirst = index % 2 !== 0
  return (
    <div className="diy-card">
      {imgFirst && (
        <div className="diy-image-first">
          <GatsbyImage image={groupProductImg.gatsbyImageData} />
        </div>
      )}
      <div className="diy-content">
        <h2>{groupProductName}</h2>
        <span>{groupProductDescription}</span>
      </div>

      {!imgFirst && (
        <div className="diy-image-last">
          <GatsbyImage image={groupProductImg.gatsbyImageData} />
        </div>
      )}
    </div>
  )
}
