import * as React from "react"

import { Layout, Seo } from "../components"
import { GroupProductCard, ThridSectionCard } from "../components/Homepage"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { StructuredText } from "react-datocms"

const IndexPage = () => {
  const { datoCmsHomePage } = useStaticQuery(graphql`
    {
      datoCmsHomePage {
        banner {
          gatsbyImageData
        }
        firstSection {
          value
        }
        secondSection {
          originalId
          groupProductName
          groupProductDescription
          groupProductImg {
            gatsbyImageData
          }
        }
        thirdSection {
          ... on DatoCmsSecondSectionCard {
            originalId
            groupProductName
            groupProductDescription
            groupProductImg {
              gatsbyImageData
            }
          }
          ... on DatoCmsSectionHeader {
            id
            originalId
            header
          }
        }
      }
    }
  `)
  const { banner, firstSection, secondSection, thirdSection } = datoCmsHomePage
  const thirdSectionHeader = thirdSection.filter(ele => ele.header)[0]
  console.log(thirdSectionHeader)
  const thirdSectionCards = thirdSection.filter(ele => !ele.header)

  return (
    <Layout>
      <Seo title="Home" />
      <div className="homepage-banner">
        <GatsbyImage image={banner.gatsbyImageData} alt="banner" />
      </div>
      <div className="container">
        <section className="homepage-first-section">
          <StructuredText data={firstSection} />
        </section>
        <section className="homepage-second-section">
          <div className="columns">
            {secondSection.map(card => (
              <GroupProductCard data={card} key={card.orignalId} />
            ))}
          </div>
        </section>
      </div>
      <section className="homepage-third-section">
        <div className="container">
          <h1 className="title">{thirdSectionHeader.header}</h1>
          {thirdSectionCards.map((card, index) => (
            <ThridSectionCard
              data={card}
              key={card.groupProductName}
              index={index}
            />
          ))}
        </div>
      </section>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
