import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export const GroupProductCard = ({ data }) => {
  const { groupProductImg, groupProductDescription, groupProductName } = data

  return (
    <div className="column">
      <div className="card group-product-card">
        <div className="card-image">
          <GatsbyImage
            image={groupProductImg.gatsbyImageData}
            alt={groupProductName}
          />
        </div>
        <div className="card-header-title is-centered">{groupProductName}</div>
        <div className="card-content">
          <div className="content" key="content">
            <span>{groupProductDescription}</span>
          </div>
          <div className="content" key="action">
            <Link to="/" className="card-action">
              Xem thêm
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
